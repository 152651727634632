import React from "react"

import Layout from "../components/layout"

const Zone = () => {
  return (
  <Layout>
  </Layout>
)}

export default Zone
